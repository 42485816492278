import {
  BotType,
  DcaTime,
  DcaType,
  PaperBtcUsdt,
  PaperExchange,
  StoplossType,
  TakeProfitType,
  WeekTime,
} from "../Const";
import * as Types from "../actions/create-bot-type";
const initState = {
  newBot: {
    id: 0,
    name: "New Bot",
    exchange: PaperExchange,
    exchangePairId: PaperBtcUsdt.id, //exchange_pair.id
    pair: PaperBtcUsdt.pair,
    strategy: "Long",
    futureType: "usd_m",
    initFund: 100,
    initFund2: 1,
    leverage: 0,
    baseOrderType: "static",
    marginMode: "cross",
    priceType: "mark",
    baseOrder: 50, //%
    extraOrderNum: 2, //%
    frequency: 15,
    takeProfit: 10,
    stoploss: 0,
    timeFrame: 365,
    entryNum: 0,
    exitNum: 0,
    entryIndicators: [],
    exitIndicators: [],
    tvAlertsEntry: [],
    tvAlertsExit: [],
    baseCoin: "BTC",
    quoteCoin: "USDT",
    applyCurrentDeal: false,
    closeBotAfterDeal: 0,
    stopBotAfterDeal: 0,
    closeEntirePosition: 0,
    stoplossType: StoplossType.absolute,
    variableTSL: [{ triggerAt: 2, stopAt: 1 }],
    fixedTSL: { triggerAt: 2, stopAt: 1 },
    takeProfitType: TakeProfitType.absolute,
    variableTP: [{ triggerAt: 2, stopAt: 1 }],
    fixedTP: { triggerAt: 2, stopAt: 1 },
    minTp: 1,
    botType: BotType.simple,
    dcaType: DcaType.time,
    dcaTime: {
      type: DcaTime.weekly,
      weekValue: WeekTime.mon,
      monthValue: "1",
    },
    dcaPercent: 2,
    dcaTrigger: 0,
    dcaExit: 0,
    dcaNoLimitFund: false,
    dcaOrder: "100",
    entryPrice: "",
    gridRangeKlineDirection: 0,
    orderLayer: "0",
    exitStrategy:"restart_bot",
    isStrategyDirty:false,
    daisyChain:0,
    firstInDaisyChain:0,
    isEditing:false,
    rental:{},
    createBotStart:false,
    sendOrderFilledEmail:1,
  },
  rentBot:{
    id: 0,
    name: "New Bot",
    exchange: PaperExchange,
    exchangePairId: PaperBtcUsdt.id, //exchange_pair.id
    pair: {},
    initFund: 100,
    baseCoin: "BTC",
    quoteCoin: "USDT",
    futureType: "usd_m",
    marginMode: "cross",
    priceType: "mark",
    leverage: 1,
    sendOrderFilledEmail:1
  },
  editingIndicator: null,
  defaultIndicators: [],
  tvAlert: false,
  tvAlertsEntry: [],
  tvAlertsExit: [],
};

const createBotReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case Types.UPDATE_EDIT_BOT_CONFIG:
      return {
        ...state,
        newBot: payload,
      };
    case Types.DEFAULT_EDIT_RENT_BOT_CONFIG:
      return {
        ...state,
        rentBot: payload
      };
    case Types.UPDATE_EDIT_RENT_BOT_CONFIG:
      return {
        ...state,
        rentBot: payload
      };
    case Types.CHANGE_PAIR:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          exchangePairId: payload && payload.id,
          pair: payload && payload.pair,
          baseCoin: payload && payload.pair.from,
          quoteCoin: payload && payload.pair.to,
          pairLabel: payload && payload.name,
          pricePrecision:payload && payload.price_precision,
        },
      };
    case Types.CHANGE_RENT_PAIR:
      return {
        ...state,
        rentBot: {
          ...state.rentBot,
          exchangePairId: payload.id,
          pair: payload.pair,
          baseCoin: payload.pair.from,
          quoteCoin: payload.pair.to,
        },
        
      };
      
    case Types.SET_RENT_BOT_SELECTED:
      return {
        ...state,
        rentBot: {
          ...state.rentBot,
          rent_id:payload.id,
          rental:payload
        },
      };
    case Types.CHANGE_STRATEGY:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          strategy: payload,
        },
      };
    case Types.CHANGE_EXCHANGE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          exchange: payload,
        },
      };
    case Types.CHANGE_SECOND_EXCHANGE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          partner: payload,
          exchange2: payload.exchange,
          exchangeId2: payload.exchange.id,
        },
      };
    case Types.FUTURE_TYPE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          futureType: payload,
        },
      };
    case Types.FUTURE_TYPE_RENT:
      return {
        ...state,
        rentBot: {
          ...state.rentBot,
          futureType: payload,
        },
      };
    case Types.CHANGE_ORDER_TYPE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          tradeOrderType: payload,
        },
      };
    case Types.CHANGE_ORDER_TYPE_EXIT:
    return {
      ...state,
      newBot: {
        ...state.newBot,
        tradeOrderTypeExit: payload,
      },
    };
    case Types.CHANGE_ORDER_TYPE_RENT:
      return {
        ...state,
        rentBot: {
          ...state.rentBot,
          tradeOrderType: payload,
        },
      };
    case Types.CHANGE_ORDER_TYPE_EXIT_RENT:
      return {
        ...state,
        rentBot: {
          ...state.rentBot,
          tradeOrderTypeExit: payload,
        },
      };
    case Types.CHANGE_FUND:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          initFund: payload,
        },
      };
    case Types.CHANGE_FUND_SECONDARY:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          initFund2: payload,
        },
      };
    case Types.CHANGE_LEVERAGE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          leverage: payload,
        },
      };
    case Types.CHANGE_LEVERAGE_RENT:
      return {
        ...state,
        rentBot: {
          ...state.rentBot,
          leverage: payload,
        },
      };
    case Types.MARGIN_MODE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          marginMode: payload,
        },
      };
    case Types.MARGIN_MODE_RENT:
      return {
        ...state,
        rentBot: {
          ...state.rentBot,
          marginMode: payload,
        },
      };
    case Types.PRICE_TYPE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          priceType: payload,
        },
      };
    case Types.PRICE_TYPE_RENT:
      return {
        ...state,
        rentBot: {
          ...state.rentBot,
          priceType: payload,
        },
      };
    case Types.CHANGE_RENT_FUND:
      return {
        ...state,
        rentBot: {
          ...state.rentBot,
          initFund: payload,
        },
      };
    case Types.CHANGE_BO:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          baseOrder: payload,
        },
      };
    case Types.CHANGE_BO_TYPE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          baseOrderType: payload,
        },
      };
    case Types.CHANGE_EO:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          extraOrderNum: payload,
        },
      };
    case Types.CHANGE_FREQUENCY:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          frequency: payload,
        },
      };
    case Types.SET_TRADING_VIEW_ENTRY_STATUS:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          tvAlertEntryStatus: payload,
          tvAlertsEntry: [],
        },
      };
    case Types.SET_TRADING_VIEW_EXIT_STATUS:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          tvAlertExitStatus: payload,
          tvAlertsExit: [],
        },
      };
    case Types.ADD_TV_ALERTS_ENTRY:
      var newBot = { ...state.newBot };
      let entryAlerts = [...newBot.tvAlertsEntry]
      var index = entryAlerts.findIndex(
        (el) => el.alert_id == payload.alert_id
      );
      if (index >= 0) {
        entryAlerts[index] = payload;
      }
      else{
        entryAlerts.push(payload)
      }
      return {
        ...state,
        newBot: {
          ...state.newBot,
          tvAlertsEntry: entryAlerts,
        },
        editingTvAlert: null,
      };
    case Types.ADD_TV_ALERTS_EXIT:
      var newBot = { ...state.newBot };
      let exitAlerts = [...newBot.tvAlertsExit]
      var index = exitAlerts.findIndex(
        (el) => el.alert_id == payload.alert_id
      );
      if (index >= 0) {
        exitAlerts[index] = payload;
      }
      else{
        exitAlerts.push(payload)
      }
      return {
        ...state,
        newBot: {
          ...state.newBot,
          tvAlertsExit: exitAlerts,
        },
        editingTvAlert: null,
      };
    case Types.CHANGE_ENTRY_NUM:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          entryNum: payload,
        },
      };
    case Types.CHANGE_ENTRY_INDICATORS:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          entryIndicators: payload,
        },
      };
    case Types.CHANGE_EXIT_NUM:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          exitNum: payload,
        },
      };
    case Types.CHANGE_EXIT_INDICATORS:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          exitIndicators: payload,
        },
      };
    case Types.EDITING_INDICATOR_SETTING:
      return {
        ...state,
        editingIndicator: payload,
      };
    case Types.EDITING_TV_ALERT:
      return {
        ...state,
        editingTvAlert: payload,
      };
    case Types.CHANGE_TV_ALERTS_EXIT:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          tvAlertsExit: payload,
        },
      };
      
    case Types.CHANGE_TV_ALERTS_ENTRY:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          tvAlertsEntry: payload,
        },
      };
    case Types.CLOSE_TV_ALERT_SETTINGS:
      return {
        ...state,
        editingTvAlert: null,
      };
    case Types.FINISHED_INDICATOR_SETTING:
      return {
        ...state,
        editingIndicator: null,
      };
    case Types.FINISHED_TV_SETTING_MODAL:
      return {
        ...state,
        showTvSettingModal: false,
      };
    case Types.CHANGE_INDICATOR_SETTING:
      var newBot = { ...state.newBot };
      var { isEntry, indicator } = payload;
      var indicators = isEntry
        ? [...newBot.entryIndicators]
        : [...newBot.exitIndicators];
      if(indicator.id){
        var index = indicators.findIndex(
          (el) => el.id == indicator.id
        );
      }
      else{
        var index = indicators.findIndex(
          (el) => el.ind_key == indicator.ind_key
        );
      }
      if (index >= 0) {
        indicators[index] = indicator;
      }
      
      return {
        ...state,
        newBot: {
          ...newBot,
          entryIndicators: isEntry ? indicators : newBot.entryIndicators,
          exitIndicators: isEntry ? newBot.exitIndicators : indicators,
          
        },
        editingIndicator: null,
      };
    case Types.UPDATE_ENTRY_NUM:
      var newBot = { ...state.newBot };
      var indicators =  [...newBot.entryIndicators]
      var entryNum = newBot.entryNum;
      let reqIndicatorNumber = indicators.filter(x => x.is_compulsory==1).length
      return {
        ...state,
        newBot: {
          ...state.newBot,
          entryNum: entryNum < reqIndicatorNumber?reqIndicatorNumber:entryNum,
        },
      };

    case Types.CHANGE_TAKE_PROFIT:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          takeProfit: payload,
        },
      };
    case Types.SET_APPLY_CURRENT_DEAL:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          applyCurrentDeal: payload,
        },
      };
    case Types.SET_CLOSE_AFTER_DEAL:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          closeBotAfterDeal: payload,
        },
      };
    case Types.SET_STOP_AFTER_DEAL:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          stopBotAfterDeal: payload,
        },
      };
    case Types.CLOSE_ENTIRE_POSITION:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          closeEntirePosition: payload,
        },
      };
    case Types.SET_BOT_NAME:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          name: payload,
        },
      };
    case Types.SET_RENT_BOT_NAME:
      return {
        ...state,
        rentBot: {
          ...state.rentBot,
          name: payload,
        },
      };
    case Types.CHANGE_ABSOLUTE_SL:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          stoploss: payload,
        },
      };
    case Types.CHANGE_SL_TYPE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          stoplossType: payload,
        },
      };
    case Types.CHANGE_TP_TYPE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          takeProfitType: payload,
        },
      };
    case Types.UPDATE_FIXED_TSL:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          fixedTSL: payload,
        },
      };
    case Types.UPDATE_VARIABLE_TSL:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          variableTSL: payload,
        },
      };
    case Types.UPDATE_FIXED_TP:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          fixedTP: payload,
        },
      };
    case Types.UPDATE_VARIABLE_TP:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          variableTP: payload,
        },
      };
    case Types.CHANGE_MIN_TP:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          minTp: payload,
        },
      };
    case Types.CHANGE_TIME_FRAME:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          timeFrame: payload,
        },
      };
    case Types.CHANGE_BACKTEST_CUSTOM:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          backtestCustom: payload,
        },
      };
    case Types.CHANGE_BACKTEST_START:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          backTestStart: payload,
        },
      };
    case Types.CHANGE_BACKTEST_END:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          backTestEnd: payload,
        },
      };
    case Types.CHANGE_BOT_TYPE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          botType: payload,
        },
      };
    case Types.CHANGE_DCA_TYPE:
      const strategy = payload === DcaType.sellUp ? "Short" : "Long";
      return {
        ...state,
        newBot: {
          ...state.newBot,
          dcaType: payload,
          strategy,
        },
      };
    case Types.CHANGE_DCA_TIME:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          dcaTime: payload,
        },
      };
    case Types.CHANGE_DCA_PERCENT:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          dcaPercent: payload,
        },
      };
    case Types.CHANGE_DCA_TRIGGER:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          dcaTrigger: payload,
        },
      };
    case Types.CHANGE_DCA_EXIT:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          dcaExit: payload,
        },
      };
    case Types.CHANGE_DCA_NO_LIMIT_FUND:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          dcaNoLimitFund: payload,
        },
      };
    case Types.CHANGE_DCA_ORDER:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          dcaOrder: payload,
        },
      };
    case Types.CHANGE_DCA_MARTINGALE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          dcaMartingale: payload,
        },
      };
    case Types.CHANGE_ENTRY_PRICE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          entryPrice: payload,
        },
      };
    case Types.UPPER_RANGE_SELL_PRICE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          gridUpperRange: payload,
        },
      };
    case Types.LOWER_RANGE_BUY_PRICE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          gridLowerRange: payload,
        },
      };
    case Types.NUMBER_OF_ORDERS:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          gridOrderNum: payload,
        },
      };
    case Types.PER_ORDER_AMOUNT:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          gridOrderVol: payload,
        },
      };
    case Types.CHANGE_GRID_ENTRY_TYPE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          gridEntryType: payload,
        },
      };
    case Types.CHANGE_GRID_RANGE_DIRECTION:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          gridRangeKlineDirection: payload,
        },
      };
    case Types.CHANGE_GRID_RANGE_VALUE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          gridRangeKlineValue: payload,
        },
      };
    case Types.CHANGE_GRID_RANGE_PERIOD:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          gridRangeKlineNum: payload,
        },
      };
    case Types.CHANGE_GRID_EXIT_WAIT_TIME:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          gridExitWaitTime: parseInt(payload),
        },
      };
    case Types.CHANGE_GRID_EXIT_OUTER_RANGE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          gridExitOuterRange: payload,
        },
      };
    case Types.CHANGE_GRID_EXIT_NO_TRADE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          gridExitStrategyNoTrade: payload,
        },
      };
    case Types.CHANGE_GRID_EXIT_HAS_TRADE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          gridExitStrategyHasTrade: payload,
        },
      };
    case Types.CHANGE_AVG_ENTRY_PRICE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          avgEntryPrice: payload,
        },
      };
    case Types.CHANGE_SELL_PRICE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          sellPrice: payload,
        },
      };
    case Types.ORDER_LAYER_CHANGED:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          orderLayer: payload,
        },
      };
    case Types.CHANGE_EXIT_STRATEGY:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          exitStrategy: payload,
        },
      };
    case Types.STRATEGY_DIRTY:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          isStrategyDirty: payload,
        },
      };
    case Types.DAISY_CHAIN:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          daisyChain: payload,
        },
      };
    case Types.FIRST_IN_DAISY_CHAIN:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          firstInDaisyChain: payload,
        },
      };
    case Types.TRIGGER_DAISY_BOT:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          triggerBotDaisyChain: payload,
        },
      };
    case Types.DO_NOT_DEACTIVATE_BOT:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          doNotDeactivateBot: payload,
        },
      };
    case Types.MIN_PRICE_GAP_PROFIT:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          minPriceGapProfit: payload,
        },
      };
    case Types.CHANGE_TRASACTION_FEE:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          transactionFee: payload,
        },
      };
    case Types.CHANGE_IS_HOLD_CURRENCY:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          holdBaseCurrency: payload,
        },
      };
    case Types.CREATE_BOT_START:
    return {
      ...state,
      newBot: {
        ...state.newBot,
        createBotStart: payload,
      },
      rentBot: {
        ...state.rentBot,
        createBotStart: payload,
      },
      createBotStart:payload
    };
    case Types.CREATE_BOT_END:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          createBotEnd: payload,
        },
      };
    case Types.SET_ORDER_FILLED_EMAIL:
      return {
        ...state,
        newBot: {
          ...state.newBot,
          sendOrderFilledEmail: payload,
        },
      };
    case Types.SET_ORDER_FILLED_EMAIL_RENT:
      return {
        ...state,
        rentBot: {
          ...state.rentBot,
          sendOrderFilledEmail: payload,
        },
      };
    default:
      return state;
  }
};

export default createBotReducer;
