import React, { useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Card, Accordion, Form, InputGroup } from "react-bootstrap";

import TutorialPopup from "../../help/TutorialPopup";
import * as HelpContent from "../../../utils/HelpContent";
import { CHANGE_ORDER_TYPE_EXIT_RENT, CHANGE_ORDER_TYPE_RENT, CHANGE_RENT_FUND } from "../../../actions/create-bot-type";
import { isLong, restrictAlphabets } from "../../../utils/Utils";
import { formatNumberToString } from "../../../utils/Utils";
import { t } from "i18next";
import LeftRightForm from "../../bots/new-bot/modules/LeftRightForm";
import BooleanView from "../../bots/new-bot/BooleanView";
import CreateRentBotSection from "../../bots/new-bot/modules/CreateRentBotSection";
import SendOrderFilledEmail from "../../bots/new-bot/modules/SendOrderFilledEmail";

const TradeParamsViewRent = (props) => {
  const { editing,t,step } = props;
  const rental = useSelector((state) => state.createBot.rentBot.rental);
  const rentBot = useSelector((state) => state.createBot.rentBot);
  const fund = rentBot.initFund;

  const currentCoin = isLong(editing?rentBot.strategy:rental.template.strategy)
    ? rentBot.quoteCoin
    : rentBot.baseCoin;
  const balances = useSelector((state) => state.bots.balances);
  const pairData = useSelector((state) => state.bots.pairData);
  const balance =
    balances && balances.find((item) => item.coin === currentCoin);
  const dispatch = useDispatch();
  useEffect(() => {
    if (balance && !editing) {
      //fundChanged(balance.free);
    }
  }, [balance]);

  const fundChanged = (val) => {
    dispatch({
      type: CHANGE_RENT_FUND,
      payload: val,
    });
  };
  const orderTypeChanged = (orderType) => {
    dispatch({
      type: CHANGE_ORDER_TYPE_RENT,
      payload: orderType,
    });
  };
  const exitOrderTypeChanged = (orderType) => {
    dispatch({
      type: CHANGE_ORDER_TYPE_EXIT_RENT,
      payload: orderType,
    });
  };

  return (
    <div className="ba-asset-form-content">
          <div className="row gx-0 gy-3">
            <LeftRightForm
                left={<><Form.Label>
                  {t("fund-alloc")} <TutorialPopup content={t("initFund-help")} />
                  </Form.Label></>}
                right={<><label data-text={currentCoin} className="input-gp-label">
                <Form.Control
                    as="input"
                    type="number"
                    placeholder="1000"
                    value={fund}
                    onChange={(e) => {
                      fundChanged(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}
                    className="input-text input-gp-text"
                  ></Form.Control>
              </label>
              <p style={{ fontSize: 13, marginTop: 10 }}>
                        Available: {formatNumberToString(balance && balance.free)}{" "}
                        {currentCoin}
                      </p>
              </>}
              ></LeftRightForm>
              <div style={{ marginTop: 20 }}>
                    <BooleanView
                        title={t("entry-order-type")}
                        firstButton="limit"
                        secondButton="market"
                        selected={rentBot.tradeOrderType}
                        onSelect={orderTypeChanged}
                        tutorial={<TutorialPopup content={t("orderType")} />}
                      />
                  
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <BooleanView
                        title={t("exit-order-type")}
                        firstButton="limit"
                        secondButton="market"
                        selected={rentBot.tradeOrderTypeExit}
                        onSelect={exitOrderTypeChanged}
                        tutorial={<TutorialPopup content={t("orderType")} />}
                      />
                    </div>
                    <SendOrderFilledEmail rent={true}></SendOrderFilledEmail>
                    <div className="col-lg-12">
                    <br></br>
                    <br></br>
                      <div className="bas-asset-submit bas-asset-btn2 bas-asset-btn3">
                      <button onClick={()=>step('prev')}>Previous</button>
                      <CreateRentBotSection rentBot={rentBot}></CreateRentBotSection>
                    </div>
                  </div>
    </div>
    </div>
  );
};

export default connect(null)(TradeParamsViewRent);
