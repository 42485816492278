import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetPassword, resetNewPassPage } from "../../actions/auth-action";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Card,
} from "react-bootstrap";
import Header from "../common/Header";
import Footer from "../common/Footer";
import "./NewPass.scss";
import GuestContainer from "./GuestContainer";
import { toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const NewPass = (props) => {
  const { resetPassword, resetNewPassPage } = props;
  const {t} = useTranslation()
  const [code, setCode] = useState("");
  const [newPass, setNewPass] = useState("");
  const [repeatPass, setRepeatPass] = useState("");

  const { resetPassStart, resetPassSuccess, resetPassError, tempEmail } =
    useSelector((state) => state.auth);
  const history = useHistory();

  useEffect(() => {
    if (resetPassSuccess) {
      toast.success("Password Reset Successfully");
      setTimeout(() => {
        history.replace("/login");
      }, 300);
    }
  }, [resetPassSuccess]);

  useEffect(() => {
    resetNewPassPage();
    return () => {
      resetNewPassPage();
    };
  }, []);

  const clickReset = (e) => {
    e.preventDefault();
    resetPassword({
      email: tempEmail,
      otp: code,
      password: newPass,
      password_confirmation: repeatPass,
    });
  };

  return (
    <>
      <GuestContainer>
      <div className="login-content-wrapper">
          <div className="login-content resetPassword-content">
              <Link to="/"><img src="/images/named-logo.png"  className="mb-3 auth-logo"></img></Link>
              <h6 className="header-text text-center">Reset Password</h6>
              <Form className="mt-5">
                <Form.Group className="ch-form">
                  <Form.Control
                    type="input"
                    placeholder="Reset code"
                    value={code}
                    className="input-text"
                    onChange={(e) => setCode(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="ch-form">
                  <Form.Control
                    type="password"
                    placeholder="New password"
                    value={newPass}
                    className="input-text"
                    onChange={(e) => setNewPass(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="ch-form">
                  <Form.Control
                    type="password"
                    placeholder="Repeat password"
                    value={repeatPass}
                    className="input-text"
                    onChange={(e) => setRepeatPass(e.target.value)}
                  />
                </Form.Group>
                <Button
                  type="submit"
                  onClick={clickReset}
                  className="blue-btn mt-4"
                  
                >
                  {resetPassStart ? "Updating..." : "Reset Password"}
                </Button>
                <Alert
                  show={resetPassSuccess || resetPassError != null}
                  variant={resetPassError ? "danger" : "success"}
                  className="mt-3"
                >
                  {" "}
                  {resetPassError || "Password has been changed!"}
                </Alert>
              </Form>
            </div>
            <div className="login-content-bottom-link">
              <ul>
                <li><Link to="/login" className="link-text">{t("login")}</Link></li>
              </ul>
            </div>
            <div className="text-center mt-5">
            {/* <span className="mr-3 mb-4">{t("have-acc")}</span> */}
            
            </div>
            </div>
       
        </GuestContainer>
    </>
  );
};
export default connect(null, { resetPassword, resetNewPassPage })(NewPass);
