import React from "react";
import { Button } from "react-bootstrap";
import "./FreeInfo.scss";

const MasterPlan = (props) => {
  const { isPremium,t } = props;
  return (
    <ul>
        <li>
              <img src="/images/sp-icon.svg" alt=""/>
              <span>100 {t("active-bots")}</span>
          </li>
          <li>
            <img src="/images/sp-icon.svg" alt=""/>
            <span>Free rentals for ALL Marketpace strategies (except blue bot)</span>
          </li>
          <li>
            <img src="/images/sp-icon.svg" alt=""/>
            <span>{t("plus-pro-plan")}</span>
          </li>
    </ul>
  );
};

export default MasterPlan;
