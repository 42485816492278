import {
  BotType,
  PaperBtcUsdt,
  PaperExchange,
  StoplossType,
  DcaType,
  DcaTime,
  WeekTime,
  TakeProfitType,
} from "../../../../Const";

export const adaptBotDefault = (
  exchange = PaperExchange,
  exchangePair = PaperBtcUsdt
) => {
  const pair = exchangePair.pair || {};
  return {
    id: null,
    name: "New Bot",
    exchange: exchange,
    exchangePairId: exchangePair.id, //exchange_pair.id
    pair: exchangePair.pair,
    strategy: "Long",
    futureType: "usd_m",
    initFund: 100,
    marginMode: "cross",
    priceType: "mark",
    baseOrderType: "static",
    tradeOrderType: "market",
    tradeOrderTypeExit: "market",
    baseOrder: 50, //%
    extraOrderNum: 2, //%
    frequency: 15,
    takeProfit: 10,
    stoploss: 0,
    timeFrame: 365,
    backtestCustom: false,
    backTestStart: new Date().getTime(),
    backTestEnd: new Date().getTime(),
    entryNum: 0,
    exitNum: 0,
    entryIndicators: [],
    exitIndicators: [],
    tvAlertsEntry: [],
    tvAlertsExit: [],
    baseCoin: pair.from,
    quoteCoin: pair.to,
    applyCurrentDeal: false,
    closeBotAfterDeal: 0,
    stopBotAfterDeal: 0,
    closeEntirePosition: 0,
    stoplossType: StoplossType.absolute,
    variableTSL: [{ id: null, triggerAt: 2, stopAt: 1 }],
    fixedTSL: { id: null, triggerAt: 2, stopAt: 1 },
    takeProfitType: TakeProfitType.absolute,
    variableTP: [{ id: null, triggerAt: 2, stopAt: 1 }],
    fixedTP: { id: null, triggerAt: 2, stopAt: 1 },
    minTp: 0,
    botType: BotType.simple,
    dcaType: DcaType.time,
    dcaTime: {
      type: DcaTime.daily,
      weekValue: WeekTime.mon,
      monthValue: "1",
    },
    dcaPercent: "2",
    dcaTrigger: "0",
    dcaExit: 0,
    dcaNoLimitFund: false,
    dcaOrder: "100",
    dcaMartingale: 0,
    entryPrice: '',
    gridRangeKlineDirection: 0,
    gridEntryType: "imd",
    gridExitStrategyNoTrade: "cancel_restart",
    gridExitStrategyHasTrade: "cancel_restart",
    gridUpperRange: "",
    gridLowerRange: "",
    gridOrderNum: "",
    gridOrderVol: "",
    gridExitWaitTime: 0,
    gridExitOuterRange: 0,
    tvAlertEntryStatus: false,
    tvAlertExitStatus: false,
    tvAlerts: [],
    avgEntryPrice:0,
    sellPrice:0,
    orderLayer: "0",
    exitStrategy:"restart_bot",
    daisyChain:0,
    firstInDaisyChain:0,
    doNotDeactivateBot:0,
    minPriceGapProfit:0,
    sendOrderFilledEmail:1
  };
};
