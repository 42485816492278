import React from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmationModal = ({ show, title, body, onCancel, onConfirm, confirmVariant = "danger", confirmText, cancelText }) => (
   <Modal show={show} onHide={onCancel} centered>
    <Modal.Header closeButton>
      <Modal.Title className="modal-title-custom">{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body className="modal-body-custom">{body}</Modal.Body>
    <Modal.Footer className="modal-footer-custom">
      <button className="grey-button" onClick={onCancel} >
        {cancelText}
      </button>
      <button  onClick={onConfirm} className="blue-button">
        {confirmText}
      </button>
    </Modal.Footer>
  </Modal>
  );

  export default ConfirmationModal;