import { adaptBotDefault } from "../components/bots/new-bot/bot-adapter/bot-default-adapter";
import { adaptBotEdit } from "../components/bots/new-bot/bot-adapter/bot-edit-adapter";
import { adaptBotCopy } from "../components/bots/new-bot/bot-adapter/bot-copy-adapter";
import { adaptRentBotDefault, adaptRentBotEdit } from "../components/bots/new-bot/bot-adapter/rent-bot-adapter";
import { adaptBotPreset } from "../components/bots/new-bot/bot-adapter/bot-preset-adapter";
import dcaExportAdapter from "../components/bots/new-bot/bot-adapter/dca-export-adapter";
import tslExportAdapter from "../components/bots/new-bot/bot-adapter/tsl_export_adapter";
import {
  BotType,
  DcaType,
  PaperBtcUsdt,
  PaperExchange,
  StoplossType,
  TakeProfitType,
} from "../Const";
import { checkSub } from "../utils/SubUtils";
import { divZeroable } from "../utils/Utils";
import { getBots } from "./bot-action";
import { getBactTestUrl, updateBotUrl, createBotUrl, createRentBotUrl } from "./const";
import * as Types from "./create-bot-type";
import network from "./network";
import { GET_BACKTEST_SUCCESS } from "./types";
import { useTranslation } from "react-i18next";
import tpsExportAdapter from "../components/bots/new-bot/bot-adapter/tps_export_adapter";



export const updateEditBotConfig = () => (dispatch, getState) => {
  const bot = getState().bots.bot || {};
  const editBot = adaptBotEdit(bot);
  dispatch({
    type: Types.UPDATE_EDIT_BOT_CONFIG,
    payload: editBot,
  });
};
export const updateEditRentBotConfig = () => (dispatch, getState) => {
  const bot = getState().bots.bot || {};
  const editBot = adaptRentBotEdit(bot);
  dispatch({
    type: Types.UPDATE_EDIT_RENT_BOT_CONFIG,
    payload: editBot,
  });
};
export const updateCopyBotConfig = (botId) => (dispatch, getState) => {
  const bot = getState().bots.bot || {};
  const copyBot = adaptBotCopy(bot);
  dispatch({
    type: Types.UPDATE_EDIT_BOT_CONFIG,
    payload: copyBot,
  });
};

export const resetBotConfig = () => (dispatch, getState) => {
  const state = getState();
  const exchange = state.settings.selectedExchange || PaperExchange;
  const usdPairs = state.bots.usdPairs || [];
  const exchangePair = usdPairs.length > 0 ? usdPairs[0] : PaperBtcUsdt;
  const defaultBot = adaptBotDefault(exchange, exchangePair);
  dispatch({
    type: Types.UPDATE_EDIT_BOT_CONFIG,
    payload: defaultBot,
  });
};
export const resetRentBotConfig = (rental) => (dispatch, getState) => {
  const state = getState();
  const exchange = state.settings.selectedExchange || PaperExchange;
  const isFuture = state.settings.isFuture;
  const usdPairs = state.bots.usdPairs || [];
  const usdMPerpetual = state.bots.usdMPerpetual;
  const coinMPerpetual =  state.bots.coinMPerpetual;
  const exchangePair = isFuture?(usdMPerpetual.length > 0 ? usdMPerpetual[0]:coinMPerpetual[0]):(usdPairs.length > 0 ? usdPairs[0] : PaperBtcUsdt);
  const defaultBot = adaptRentBotDefault(exchange, exchangePair,rental);
  dispatch({
    type: Types.DEFAULT_EDIT_RENT_BOT_CONFIG,
    payload: defaultBot,
  });
};

export const updatePresetConfig = (config) => (dispatch, getState) => {
  const state = getState();
  const exchange = state.settings.selectedExchange || PaperExchange;
  const indicators = state.bots.indicators || [];
  const quoteCoin = config.quoteCoin || "USDT";
  const baseCoin = config.baseCoin || "BTC";
  let pairs = state.bots.usdPairs || [];
  if (quoteCoin.includes("BTC")) {
    pairs = state.bots.btcPairs;
  } else if (quoteCoin.includes("ETH")) {
    pairs = state.bots.ethPairs;
  }
  const pair = pairs.find(
    (el) => el.pair.from === baseCoin && el.pair.to === quoteCoin
  );
  const exchangePair = pairs.length > 0 ? pair || pairs[0] : PaperBtcUsdt;
  const presetBot = adaptBotPreset(exchange, exchangePair, config, indicators);
  dispatch({
    type: Types.UPDATE_EDIT_BOT_CONFIG,
    payload: presetBot,
  });
};

const getSL = (newBot) => {
  let sl = newBot.stoploss;
  let tsls = [];
  switch (newBot.stoplossType) {
    case StoplossType.fixedTsl:
      sl = 0;
      tsls = [newBot.fixedTSL].map((el) =>
        tslExportAdapter(el, StoplossType.fixedTsl)
      );
      break;
    case StoplossType.variableTsl:
      sl = 0;
      tsls = newBot.variableTSL.map((el) =>
        tslExportAdapter(el, StoplossType.variableTsl)
      );
      break;
    default:
      break;
  }
  return { sl, tsls };
};
const getTP = (newBot) => {
  let tp = newBot.takeProfit;
  let tps = [];
  switch (newBot.takeProfitType) {
    case TakeProfitType.fixedTP:
      tp = 0;
      tps = [newBot.fixedTP].map((el) =>
        tpsExportAdapter(el, TakeProfitType.fixedTP)
      );
      break;
    case TakeProfitType.variableTP:
      tp = 0;
      tps = newBot.variableTP.map((el) =>
        tpsExportAdapter(el, TakeProfitType.variableTP)
      );
      break;
    default:
      break;
  }
  return { tp, tps };
};

const getParams = (newBot, subPremium) => {
  let arbitrageBot = (newBot.botType==BotType.inter_arbitrage || newBot.botType==BotType.intra_arbitrage);
  if (newBot.entryNum == 0 && newBot.botType !== BotType.dca && newBot.botType !== BotType.exit && newBot.botType !== BotType.price && newBot.botType !== BotType.grid && newBot.botType !== BotType.sell && !arbitrageBot) {
    return { err: "pls-sel-one-entry", params: null };
  }
  if (newBot.entryPrice == '' && newBot.botType == BotType.price) {
    return { err: "pls-sel-one-entry", params: null };
  }

  if (!subPremium && newBot.frequency == 120)
    return { err: "pls-upgrade-2h" };

  if (!subPremium && newBot.extraOrderNum > 5)
    return { err: "pls-upgrade-5-orders" };

  let initFund = newBot.initFund;
  if (initFund <= 0 && !newBot.dcaNoLimitFund && newBot.botType !== BotType.grid)
    return { err: "Please set your Fund Allocation in the Trade Parameters section" };
  let initFund2 = newBot.initFund2;
  let baseOrder = newBot.baseOrder;
  let extraOrder = divZeroable(100 - newBot.baseOrder, newBot.extraOrderNum);
  let strategy = newBot.strategy;
  let allTvAlerts = [...newBot.tvAlertsEntry, ...newBot.tvAlertsExit];
  let frequency = newBot.frequency
  // Indicators
  const entryIds = newBot.entryIndicators.map((el) => ({
    ...el,
    type: "entry",
  }));
  const exitIds = newBot.exitIndicators.map((el) => ({ ...el, type: "exit" }));

  // TSL
  const { sl, tsls } = getSL(newBot);
  // TPS
  const { tp, tps } = getTP(newBot);

  // DCA
  const { dcaType, dcaValue, dcaValue2, dcaValue3 } = dcaExportAdapter(newBot);
  if (newBot.botType === BotType.dca) {
    initFund = newBot.dcaNoLimitFund ? 0 : newBot.initFund;
    baseOrder = newBot.dcaOrder;
    extraOrder = newBot.dcaExit;
    strategy = newBot.dcaType === DcaType.sellUp ? "Short" : "Long";
  }
  if (newBot.botType === BotType.exit) {
    extraOrder = 0;
    baseOrder = 100;
  }
  if (newBot.botType === BotType.grid) {
    if(newBot.gridEntryType==="imd"){
      frequency = 5
    }
    
  }
  const params = {
    id: newBot.id,
    name: newBot.name,
    type: newBot.botType,
    exchange_pair_id: newBot.exchangePairId,
    exchange_id: newBot.exchange.id,
    exchange_id2: newBot.exchange2 && newBot.exchange2.id,
    is_multi_layer: newBot.orderLayer,
    strategy,
    initial_fund: initFund,
    initial_fund2: initFund2,
    base_order_percentage: baseOrder,
    base_order_type: newBot.baseOrderType,
    extra_order_percentage: extraOrder,
    order_type: newBot.tradeOrderType,
    frequency: frequency,
    profit: tp,
    tps,
    stop_loss: sl,
    tsls,
    back_test_time_frame: newBot.timeFrame,
    bot_indicators: [...entryIds, ...exitIds],
    tv_alerts: allTvAlerts,
    indicator_triggers_entry: newBot.entryNum,
    indicator_triggers_exit: newBot.exitNum,
    min_tp: newBot.minTp,
    dca_entry_type: dcaType,
    dca_value: dcaValue,
    dca_value2: dcaValue2,
    dca_value3: dcaValue3,
    apply_to_current_deal:newBot.applyCurrentDeal,
    off_after_close_deal:newBot.closeBotAfterDeal,
    disable_after_deal_closed:newBot.stopBotAfterDeal,
    close_entire_pos:newBot.closeEntirePosition,
    entry_price : newBot.entryPrice,
    grid_upper_range:newBot.gridUpperRange,
    grid_lower_range:newBot.gridLowerRange,
    grid_order_num:newBot.gridOrderNum,
    grid_order_vol:newBot.gridOrderVol,
    grid_entry_type:newBot.gridEntryType,
    grid_range_kline_value:newBot.gridRangeKlineValue,
    grid_range_kline_num:newBot.gridRangeKlineNum,
    grid_range_kline_direction:newBot.gridRangeKlineDirection,
    grid_exit_wait_time:newBot.gridExitWaitTime,
    grid_exit_outer_range:newBot.gridExitOuterRange,
    grid_exit_strategy_has_trade:newBot.gridExitStrategyHasTrade,
    grid_exit_strategy_no_trade:newBot.gridExitStrategyNoTrade,
    avg_entry_price:newBot.avgEntryPrice,
    sell_price:newBot.sellPrice,
    leverage: newBot.leverage,
    margin_mode: newBot.marginMode,
    price_type: newBot.priceType,
    back_test_start: newBot.backTestStart,
    back_test_end: newBot.backTestEnd,
    exit_strategy:newBot.exitStrategy,
    daisy_chain:newBot.daisyChain,
    first_in_daisy_chain:newBot.firstInDaisyChain,
    trigger_bot_in_daisy_chain:newBot.triggerBotDaisyChain,
    do_not_deactivate:newBot.doNotDeactivateBot,
    extra_price_gap_min:newBot.minPriceGapProfit,
    transaction_fee:newBot.transactionFee,
    hold_base_currency:newBot.holdBaseCurrency,
    order_type_exit: newBot.tradeOrderTypeExit,
    order_filled_email_enabled: newBot.sendOrderFilledEmail,
  };
  if(!newBot.backtestCustom){
    delete params.back_test_start
    delete params.back_test_end
  }
  if (newBot.botType === BotType.exit || newBot.botType === BotType.price) {
    delete params.indicator_triggers_entry
  }
  if (newBot.tvAlertEntryStatus && newBot.tvAlertsEntry.length > 0) {
    delete params.bot_indicators
  }
  if (newBot.tvAlertExitStatus && newBot.tvAlertsExit.length > 0) {
    delete params.bot_indicators
  }
  if (newBot.botType === BotType.grid) {
    delete params.initial_fund
  }
  if (allTvAlerts.length == 0) {
    delete params.tv_alerts
  }
  if (!newBot.takeProfit && newBot.takeProfit!=0) 
    delete params.profit
  if (newBot.takeProfit=="") 
    delete params.profit
  return { err: null, params };
};

export const updateBot = (itemsPerPage,pageNum,selectedTab,selectedExchangeId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState();
    const newBot = state.createBot.newBot;
    const { subPremium } = checkSub(state.auth.user.user_subscription);

    const { err, params } = getParams(newBot, subPremium);
    if (err) {
      return reject(err);
    }
    network
      .post(newBot.id ? updateBotUrl+newBot.id : createBotUrl, params, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code == 200) {
          resolve();
          dispatch(getBots(selectedExchangeId,itemsPerPage,pageNum,selectedTab));
        } else {
          reject(message);
        }
      })
      .catch(reject);
  });
};

export const runBackTest = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState();
    const newBot = state.createBot.newBot;
    const { subPremium } = checkSub(state.auth.user.user_subscription);
    const { err, params } = getParams(newBot, subPremium);
    if (err) {
      return reject(err);
    }

    network
      .get(getBactTestUrl, params, dispatch, getState)
      .then((res) => {
        const { code, message, bot } = res.data;
        if (code == 200) {
          const backtest = {
            ...bot.back_test,
            initialFund: params.initial_fund,
          };
          dispatch({
            type: GET_BACKTEST_SUCCESS,
            payload: backtest,
          });
          const orders = bot.back_test.positions
            .flatMap((position) => {
              return position.deal_trade_histories;
            })
            .map((item) => {
              return {
                name: item.name,
                desc: `${item.quantity} ${newBot.baseCoin} @ ${item.price} ${newBot.quoteCoin}`,
                created_at: item.created_at,
                side: item.side,
                status: item.status,
              };
            });
          loadTradesToTV(params.strategy, orders);
          resolve();
        } else {
          reject(message);
          loadTradesToTV(params.strategy, []);
        }
      })
      .catch(reject);
  });
};

const loadTradesToTV = (strategy, orders) => {
  window.bot = {
    strategy,
    orders,
  };
};
const getParamsRentBot = (newBot, subPremium) => {

  let initFund = parseFloat(newBot.initFund);
  const params = {
    id: newBot.id,
    rent_id: newBot.rent_id,
    name: newBot.name,
    exchange_pair_id: newBot.exchangePairId,
    exchange_id: newBot.exchange.id,
    initial_fund: initFund,
    order_type:newBot.tradeOrderType,
    order_type_exit:newBot.tradeOrderTypeExit,
    leverage : newBot.leverage,
    price_type: newBot.priceType,
    margin_mode: newBot.marginMode,
    order_filled_email_enabled:newBot.sendOrderFilledEmail,

  };
  return { err: null, params };
}

export const createRentBot = (itemsPerPage,pageNum,selectedTab,selectedExchangeId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState();
    const rentBot = state.createBot.rentBot;
    
    const { subPremium } = checkSub(state.auth.user.user_subscription);

    const { err, params } = getParamsRentBot(rentBot, subPremium);
    if (err) {
      return reject(err);
    }
    network
      .post(createRentBotUrl, params, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code == 200) {
          resolve();
          dispatch(getBots(selectedExchangeId,itemsPerPage,pageNum,selectedTab));
        } else {
          reject(message);
        }
      })
      .catch(reject);
  });
};
export const updateRentBot = (itemsPerPage,pageNum,selectedTab,selectedExchangeId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState();
    const rentBot = state.createBot.rentBot;
    
    const { subPremium } = checkSub(state.auth.user.user_subscription);

    const { err, params } = getParamsRentBot(rentBot, subPremium);
    if (err) {
      return reject(err);
    }
    network
      .post(updateBotUrl+rentBot.id, params, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code == 200) {
          resolve();
          dispatch(getBots(selectedExchangeId,itemsPerPage,pageNum,selectedTab));
        } else {
          reject(message);
        }
      })
      .catch(reject);
  });
};
export const setupSimpleBot = ()=>(dispatch, getState)=>{
  const state = getState();
  const defaultIndicators = state.bots.indicators || [];
  //Setup RSI as entry condition 
  var element = defaultIndicators.find((el) => el.indicator_id == 4);

  dispatch({
    type: Types.CHANGE_BOT_TYPE,
    payload: BotType.simple,
  });
  dispatch({
    type: Types.SET_BOT_NAME,
    payload: "MY FIRST BOT",
  });
  dispatch({
    type: Types.CHANGE_FUND,
    payload: 1000,
  });

  dispatch({
    type: Types.CHANGE_ENTRY_INDICATORS,
    payload: [{...element,ind_key:Math.floor(Math.random() * 10000)}],
  });

  //change entry number to 1
  dispatch({
    type: Types.CHANGE_ENTRY_NUM,
    payload: 1,
  });

  //set take profit to 2%
  dispatch({
    type: Types.CHANGE_TAKE_PROFIT,
    payload: 2,
  });

  //set stoploss to 10 %

  dispatch({
    type: Types.CHANGE_ABSOLUTE_SL,
    payload: 10,
  });
}
