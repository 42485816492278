import React, { useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Card, Accordion, Form, InputGroup } from "react-bootstrap";

import TutorialPopup from "../../help/TutorialPopup";
import * as HelpContent from "../../../utils/HelpContent";
import { CHANGE_FUND, CHANGE_ORDER_TYPE, CHANGE_ORDER_TYPE_EXIT, DO_NOT_DEACTIVATE_BOT } from "../../../actions/create-bot-type";
import { isLong, restrictAlphabets } from "../../../utils/Utils";
import { formatNumberToString } from "../../../utils/Utils";
import NormalParamsView from "./normal-bot/NormalParamsView";
import { BotType, CreateBotModules } from "../../../Const";
import DcaParamsView from "./dca-bot/DcaParamsView";
import PriceBotParamsView from "./price-bot/PriceBotParamsView";
import BooleanView from "./BooleanView";
import { FUND_ALLOCATION } from "../../../actions/types";
import SellBotParamsView from "./sell-bot/SellBotParamsView";
import InterArbTradeParamsView from "./inter-arbitrage-bot/InterArbTradeParamsView";
import IntraArbTradeParamsView from "./intra-arbitrage-bot/IntraArbTradeParamsView";
import LeftRightForm from "./modules/LeftRightForm";
import CustomCheckbox from "./modules/CustomCheckbox";
import SendOrderFilledEmail from "./modules/SendOrderFilledEmail";

const TradeParamsView = (props) => {
  const { newBot, editing,t,step } = props;

  const fund = newBot.initFund;
  const arbitrageBot = (newBot.botType==BotType.inter_arbitrage || newBot.botType==BotType.intra_arbitrage);
  const isSellBot = newBot.botType == BotType.sell
  const donotDeactivateBots = (newBot.botType==BotType.advance || newBot.botType==BotType.dca);
  const strategy = isSellBot?"Short":newBot.strategy
  const currentCoin = isLong(strategy)
    ? newBot.quoteCoin
    : newBot.baseCoin;
  const balances = useSelector((state) => state.bots.balances);
  const balance =
    balances && balances.find((item) => item.coin === currentCoin);
  const dispatch = useDispatch();
  const getParamsView = ()=>{
    switch (newBot.botType) {
      case BotType.dca:
        return <DcaParamsView newBot={newBot} currentCoin={currentCoin} t={t}/>
      case BotType.price :
        return <PriceBotParamsView newBot={newBot} t={t}/>
      case BotType.sell :
        return <SellBotParamsView newBot={newBot} t={t}/>
      case BotType.inter_arbitrage :
        return <InterArbTradeParamsView newBot={newBot} t={t} editing={editing} />
      case BotType.intra_arbitrage :
        return <IntraArbTradeParamsView newBot={newBot} t={t} editing={editing} />
      default:
        return <NormalParamsView newBot={newBot} t={t} />
    }
  }
  useEffect(() => {
    if (balance && !editing && !(newBot.botType==BotType.intra_arbitrage)) {
      //fundChanged(balance.free);
    }
  }, [balance]);

  const fundChanged = (val) => {
    dispatch({
      type: CHANGE_FUND,
      payload: val,
    });
  };
  const orderTypeChanged = (orderType) => {
    dispatch({
      type: CHANGE_ORDER_TYPE,
      payload: orderType,
    });
  };
  const exitOrderTypeChanged = (orderType) => {
    dispatch({
      type: CHANGE_ORDER_TYPE_EXIT,
      payload: orderType,
    });
  };
  const handleDonNotDeactivate = (val) => {
    dispatch({
      type: DO_NOT_DEACTIVATE_BOT,
      payload: val?1:0,
    });
  };

  return (
     <div className="ba-asset-form-content">
        <div className="row gx-0 gy-3">
          {!arbitrageBot?
          <>
          {!newBot.dcaNoLimitFund   && (
            <>
            
            <LeftRightForm
            left={<><h6>{isSellBot?t("amount-to-sell"):<>{t("fund-alloc")}<TutorialPopup content={t("initFund-help")} /></>}</h6></>}
            right={<><label data-text={currentCoin} className="input-gp-label">
            <Form.Control
                as="input"
                type="number"
                placeholder="1000"
                value={fund}
                onChange={(e) => {
                  fundChanged(e.target.value);
                }}
                onKeyPress={(e) => {
                  restrictAlphabets(e);
                }}
                className="input-text input-gp-text"
              ></Form.Control>
          </label>
              <p style={{ fontSize: 13, marginTop: 10 }}>
                {t("available")}: {formatNumberToString(balance && balance.free)}{" "}
                {currentCoin}
              </p>
              <br></br>
              {donotDeactivateBots && 
             
             <CustomCheckbox 
             val={newBot.doNotDeactivateBot} 
             checked={newBot.doNotDeactivateBot==1} 
             callback={handleDonNotDeactivate}
             label={t("do-not-deactivate-bot")}
             id={t("do-not-deactivate-bot")}
             />
             }
          </>}
            ></LeftRightForm>
            </> )}
           
            <div style={{ marginTop: 20 }}>
            <BooleanView
                title={t("order-type")}
                firstButton="limit"
                secondButton="market"
                selected={newBot.tradeOrderType}
                onSelect={orderTypeChanged}
                tutorial={<TutorialPopup content={t("orderType")} />}
              />
            </div>  
            <div style={{ marginTop: 20 }}>
               <BooleanView
                title={t("exit-order-type")}
                firstButton="limit"
                secondButton="market"
                selected={newBot.tradeOrderTypeExit}
                onSelect={exitOrderTypeChanged}
                tutorial={<TutorialPopup content={t("orderType")} />}
              />
            </div>

          </>
          :<></>}
        {getParamsView()}
        <SendOrderFilledEmail></SendOrderFilledEmail>
        <div className="col-lg-12">
              <div className="bas-asset-submit bas-asset-btn2">
                <button onClick={()=>step('prev')}>Previous</button>
                <button onClick={()=>step('next')}>Next</button>
              </div>
            </div>
          
    </div>
    </div>
  );
};

export default connect(null)(TradeParamsView);
