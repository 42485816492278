import { StoplossType, DcaType, DcaTime, WeekTime, TakeProfitType } from "../../../../Const";
import { divZeroable, isLong } from "../../../../utils/Utils";
import tpsImportAdapter from "./tps_import_adapter";
import tslImportAdapter from "./tsl_import_adapter";

export const adaptBotEdit = (bot) => {
  if (!bot) return null;
  const exchangePair = bot.exchange_pair || {};
  const exchange = exchangePair.exchange || {};
  const exchange2 = bot.exchange2 || {};
  const pair = exchangePair.pair || {};
  const baseOrder = bot.base_order_percentage || 100;
  const extraOrder = bot.extra_order_percentage || 0;
  const extraOrderNum = Math.round(divZeroable(100 - baseOrder, extraOrder));

  // Indicators
  const indicators = bot.bot_indicators || [];
  const botTvAlerts = bot.tv_alerts || [];
  const tvAlertsEntry = botTvAlerts.filter((el) => el.type == "entry").map((item,id)=>{ return {...item,alert_id:id} })|| [];
  const tvAlertsExit = botTvAlerts.filter((el) => el.type == "exit").map((item,id)=>{ return {...item,alert_id:id} })|| [];
  const entryIndicators = indicators.filter((el) => el.type == "entry");
  const exitIndicators = indicators.filter((el) => el.type == "exit");

  // TSLS
  const tsls = (bot.tsls && bot.tsls.map(tslImportAdapter)) || [];
  tsls.sort((el1, el2) => el1.triggerAt - el2.triggerAt);
  let slType = StoplossType.absolute;
  if (tsls.length) {
    slType = tsls[0].type;
  }
  // TSLS
  const tps = (bot.tps && bot.tps.map(tpsImportAdapter)) || [];
  tps.sort((el1, el2) => el1.triggerAt - el2.triggerAt);
  let tpType = TakeProfitType.absolute;
  if (tps.length) {
    tpType = tps[0].type;
  }

  // DCA
  let dcaType = DcaType.time;
  let dcaTimeType = DcaTime.weekly;
  let dcaTimeWeekValue = WeekTime.mon;
  let dcaTimeMonthValue = "1";
  let dcaPercent = 2;
  let dcaTrigger = 0;
  let dcaNoLimitFund = false;
  let dcaMartingale = 0;
  let dcaSetting = bot.dca_setting;

  //Grid
  let gridUpperRange = 0
  let gridLowerRange = 0
  let gridOrderNum = 0
  let gridOrderVol = 0
  let gridEntryType = "imd"
  let gridRangeKlineValue = 0
  let gridRangeKlineNum = 0
  let gridRangeKlineDirection = 0
  let gridExitWaitTime = 0
  let gridExitOuterRange = 0
  let gridExitStrategyHasTrade = 0
  let gridExitStrategyNoTrade = "cancel_restart"
  let avgEntryPrice = 0
  let sellPrice = 0
  let leverage = 10
  let marginMode = "cross"
  let offAfterCloseDeal = 0
  let priceType = "mark"
  let orderLayer = "0"
  let exitStrategy = "restart_bot"
  let daisyChain = 0
  let firstInDaisyChain = 0
  let botConfigs = bot.configs;
  let triggerBotDaisyChain = "";
  let doNotDeactivateBot = 0;
  let minPriceGapProfit = 0;
  let holdBaseCurrency = 0;
  let sendOrderFilledEmail=1;
  if(botConfigs){
    gridUpperRange = botConfigs.grid_upper_range
    gridLowerRange = botConfigs.grid_lower_range
    gridOrderNum = botConfigs.grid_order_num
    gridOrderVol = botConfigs.grid_order_vol
    gridEntryType = botConfigs.grid_entry_type
    gridRangeKlineValue = botConfigs.grid_range_kline_value
    gridRangeKlineNum = botConfigs.grid_range_kline_num
    gridRangeKlineDirection =  botConfigs.grid_range_kline_direction?botConfigs.grid_range_kline_direction:0;
    gridExitWaitTime  =  botConfigs.grid_exit_wait_time?botConfigs.grid_exit_wait_time:0;
    gridExitOuterRange  =  botConfigs.grid_exit_outer_range
    gridExitStrategyHasTrade = botConfigs.grid_exit_strategy_has_trade
    gridExitStrategyNoTrade = botConfigs.grid_exit_strategy_no_trade
    avgEntryPrice = botConfigs.avg_entry_price
    leverage = botConfigs.leverage
    marginMode = botConfigs.margin_mode
    offAfterCloseDeal = botConfigs.off_after_close_deal
    priceType = botConfigs.price_type
    exitStrategy = botConfigs.exit_strategy
    orderLayer = botConfigs.is_multi_layer
    daisyChain = botConfigs.daisy_chain
    firstInDaisyChain =  botConfigs.first_in_daisy_chain
    triggerBotDaisyChain =  botConfigs.trigger_bot_in_daisy_chain
    doNotDeactivateBot =  botConfigs.do_not_deactivate
    minPriceGapProfit =  botConfigs.extra_price_gap_min
    holdBaseCurrency =  botConfigs.hold_base_currency
  }
  if (dcaSetting) {
    let entryType = dcaSetting.entry_type;
    if (entryType === "percentage") {
      dcaType = isLong(bot.strategy) ? DcaType.buyDown : DcaType.sellUp;
    } else {
      dcaType = entryType;
    }
    dcaNoLimitFund = bot.initial_fund == 0;

    switch (dcaType) {
      case DcaType.time:
        dcaTimeType = dcaSetting.value;
        if (dcaTimeType === DcaTime.weekly) {
          dcaTimeWeekValue = dcaSetting.value2;
        } else if (dcaTimeType === DcaTime.monthly) {
          dcaTimeMonthValue = dcaSetting.value2;
        }
        break;
      case DcaType.indicator:
        dcaMartingale = parseInt(dcaSetting.value3);
        break;
      case DcaType.buyDown:
      case DcaType.sellUp:
        dcaTrigger = dcaSetting.value;
        dcaPercent = dcaSetting.value2;
        dcaMartingale = parseInt(dcaSetting.value3);
        break;
      default:
        break;
    }
  }
  

  return {
    id: bot.id,
    name: bot.name,
    exchange,
    exchange2,
    exchangePairId: exchangePair.id, //exchange_pair.id
    pair,
    strategy: bot.strategy,
    futureType: exchangePair.future_type,
    initFund: bot.initial_fund,
    initFund2: bot.initial_fund2,
    baseOrderType: bot.base_order_type,
    baseOrder, //%
    extraOrderNum, //%
    frequency: bot.frequency,
    takeProfit: bot.profit,
    stoploss: bot.stop_loss,
    timeFrame: 365,
    backtestCustom: false,
    backTestStart: new Date().getTime(),
    backTestEnd: new Date().getTime(),
    entryNum: bot.indicator_triggers_entry,
    exitNum: bot.indicator_triggers_exit,
    tradeOrderType: bot.order_type,
    tradeOrderTypeExit: bot.order_type_exit,
    entryIndicators,
    exitIndicators,
    tvAlertsEntry,
    tvAlertsExit,
    tvAlertEntryStatus: tvAlertsEntry.length>0?true:false,
    tvAlertExitStatus: tvAlertsExit.length>0?true:false,
    baseCoin: pair.from,
    quoteCoin: pair.to,
    applyCurrentDeal: false,
    closeBotAfterDeal: bot.off_after_close_deal,
    stopBotAfterDeal: bot.disable_after_deal_closed,
    closeEntirePosition: bot.close_entire_pos,
    stoplossType: slType,
    variableTSL: tsls.length ? tsls : [{ triggerAt: "2", stopAt: "1" }],
    fixedTSL: tsls.length ? tsls[0] : { triggerAt: "2", stopAt: "1" },
    takeProfitType: tpType,
    variableTP: tps.length ? tps : [{ triggerAt: "2", stopAt: "1" }],
    fixedTP: tps.length ? tps[0] : { triggerAt: "2", stopAt: "1" },
    minTp: bot.min_tp || 0,
    botType: bot.type,
    dcaType: dcaType,
    dcaTime: {
      type: dcaTimeType,
      weekValue: dcaTimeWeekValue,
      monthValue: dcaTimeMonthValue,
    },
    dcaPercent,
    dcaTrigger,
    dcaExit: extraOrder,
    dcaNoLimitFund,
    dcaOrder: baseOrder,
    dcaMartingale,
    entryPrice: bot.entry_price,
    gridUpperRange:gridUpperRange,
    gridLowerRange:gridLowerRange,
    gridOrderNum:gridOrderNum,
    gridOrderVol:gridOrderVol,
    gridEntryType:gridEntryType,
    gridRangeKlineValue:gridRangeKlineValue,
    gridRangeKlineNum:gridRangeKlineNum,
    gridRangeKlineDirection:gridRangeKlineDirection,
    gridExitWaitTime:parseInt(gridExitWaitTime),
    gridExitOuterRange:gridExitOuterRange,
    gridExitStrategyHasTrade:gridExitStrategyHasTrade,
    gridExitStrategyNoTrade:gridExitStrategyNoTrade,
    avgEntryPrice:avgEntryPrice,
    sellPrice:sellPrice,
    leverage:parseInt(leverage),
    marginMode : marginMode,
    offAfterCloseDeal :offAfterCloseDeal,
    priceType :priceType ,
    exitStrategy:exitStrategy,
    orderLayer:orderLayer,
    daisyChain:daisyChain,
    firstInDaisyChain:firstInDaisyChain,
    triggerBotDaisyChain:triggerBotDaisyChain,
    doNotDeactivateBot:doNotDeactivateBot,
    minPriceGapProfit:minPriceGapProfit,
    holdBaseCurrency:holdBaseCurrency,
    isEditing:true,
    pricePrecision:exchangePair && exchangePair.price_precision,
    sendOrderFilledEmail:bot.order_filled_email_enabled
  };
};
