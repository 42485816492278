import React from "react";
import CustomCheckbox from "./CustomCheckbox";
import { SET_ORDER_FILLED_EMAIL, SET_ORDER_FILLED_EMAIL_RENT } from "../../../../actions/create-bot-type";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LeftRightForm from "./LeftRightForm";

const SendOrderFilledEmail = (props)=>{
    const {rent} = props
    const newBot = useSelector((state) => state.createBot.newBot);
    const rentBot = useSelector((state) => state.createBot.rentBot);
    const {t} = useTranslation()
    const sendOrderFilledEmail = parseInt(rent?rentBot.sendOrderFilledEmail:newBot.sendOrderFilledEmail)?1:0;
    const dispatch = useDispatch()
    const setOrderFilledEmail = (val) => {
        dispatch({
          type: rent?SET_ORDER_FILLED_EMAIL_RENT:SET_ORDER_FILLED_EMAIL,
          payload: val?1:0,
        });
      };
    return(<>
            <LeftRightForm 
            left={<></>}
            right={ <CustomCheckbox 
                val={sendOrderFilledEmail} 
                checked={sendOrderFilledEmail} 
                callback={setOrderFilledEmail}
                label={t("send-orderfilled")}
                id={'send-orderfilled'}
                >
                </CustomCheckbox>}
            >
            </LeftRightForm>
    </>)
}

export default SendOrderFilledEmail;