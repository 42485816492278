export const UPDATE_EDIT_BOT_CONFIG = "UPDATE_EDIT_BOT_CONFIG";
export const UPDATE_EDIT_RENT_BOT_CONFIG = "UPDATE_EDIT_RENT_BOT_CONFIG";
export const DEFAULT_EDIT_RENT_BOT_CONFIG = "DEFAULT_EDIT_RENT_BOT_CONFIG";
export const CHANGE_PAIR = "CHANGE_PAIR";
export const CHANGE_STRATEGY = "CHANGE_STRATEGY";
export const CHANGE_EXCHANGE = "CHANGE_EXCHANGE";
export const FUTURE_TYPE = "FUTURE_TYPE";
export const FUTURE_TYPE_RENT = "FUTURE_TYPE_RENT";
export const CHANGE_FUND = "CHANGE_FUND";
export const MARGIN_MODE = "MARGIN_MODE";
export const MARGIN_MODE_RENT = "MARGIN_MODE_RENT";
export const PRICE_TYPE = "PRICE_TYPE";
export const PRICE_TYPE_RENT = "PRICE_TYPE_RENT";
export const CHANGE_LEVERAGE = "CHANGE_LEVERAGE";
export const CHANGE_LEVERAGE_RENT = "CHANGE_LEVERAGE_RENT";
export const CHANGE_BO = "CHANGE_BO";
export const CHANGE_BO_TYPE = "CHANGE_BO_TYPE";
export const CHANGE_EO = "CHANGE_EO";
export const CHANGE_FREQUENCY = "CHANGE_FREQUENCY";
export const CHANGE_ENTRY_NUM = "CHANGE_ENTRY_NUM";
export const CHANGE_ENTRY_INDICATORS = "CHANGE_ENTRY_INDICATORS";
export const CHANGE_EXIT_NUM = "CHANGE_EXIT_NUM";
export const UPDATE_ENTRY_NUM = "UPDATE_ENTRY_NUM";
export const CHANGE_EXIT_INDICATORS = "CHANGE_EXIT_INDICATORS";
export const EDITING_INDICATOR_SETTING = "EDITING_INDICATOR_SETTING";
export const CHANGE_INDICATOR_SETTING = "CHANGE_INDICATOR_SETTING";
export const CHANGE_TV_ALERTS = "CHANGE_TV_ALERTS";
export const ADD_TV_ALERTS_ENTRY = "ADD_TV_ALERTS_ENTRY";
export const ADD_TV_ALERTS_EXIT = "ADD_TV_ALERTS_EXIT";
export const EDITING_TV_ALERT = "EDITING_TV_ALERT";
export const CHANGE_TV_ALERTS_EXIT = "CHANGE_TV_ALERTS_EXIT";
export const CHANGE_TV_ALERTS_ENTRY = "CHANGE_TV_ALERTS_ENTRY";
export const FINISHED_INDICATOR_SETTING = "FINISHED_EDITING_INDICATOR_SETTING";
export const FINISHED_TV_SETTING_MODAL = "FINISHED_TV_SETTING_MODAL";
export const CHANGE_TAKE_PROFIT = "CHANGE_TAKE_PROFIT";
export const CHANGE_ABSOLUTE_SL = "CHANGE_ABSOLUTE_SL";
export const SET_APPLY_CURRENT_DEAL = "SET_APPLY_CURRENT_DEAL";
export const SET_BOT_NAME = "SET_BOT_NAME";
export const SET_RENT_BOT_NAME = "SET_RENT_BOT_NAME";
export const CHANGE_SL_TYPE = "CHANGE_SL_TYPE";
export const CHANGE_TP_TYPE = "CHANGE_TP_TYPE";
export const UPDATE_FIXED_TSL = "UPDATE_FIXED_TSL";
export const UPDATE_FIXED_TP = "UPDATE_FIXED_TP";
export const UPDATE_VARIABLE_TSL = "UPDATE_VARIABLE_TSL";
export const UPDATE_VARIABLE_TP = "UPDATE_VARIABLE_TP";
export const CHANGE_MIN_TP = "CHANGE_MIN_TP";
export const CHANGE_TIME_FRAME = "CHANGE_TIME_FRAME";
export const CHANGE_BACKTEST_START = "CHANGE_BACKTEST_START";
export const CHANGE_BACKTEST_END = "CHANGE_BACKTEST_END";
export const CHANGE_BACKTEST_CUSTOM = "CHANGE_BACKTEST_CUSTOM";
export const CHANGE_BOT_TYPE = "CHANGE_BOT_TYPE";
export const CHANGE_DCA_TYPE = "CHANGE_DCA_TYPE";
export const CHANGE_DCA_TIME = "CHANGE_DCA_TIME";
export const CHANGE_DCA_PERCENT = "CHANGE_DCA_PERCENT";
export const CHANGE_DCA_TRIGGER = "CHANGE_DCA_TRIGGER";
export const CHANGE_DCA_EXIT = "CHANGE_DCA_EXIT";
export const CHANGE_DCA_NO_LIMIT_FUND = "CHANGE_DCA_NO_LIMIT_FUND";
export const CHANGE_DCA_ORDER = "CHANGE_DCA_ORDER";
export const CHANGE_DCA_MARTINGALE = "CHANGE_DCA_MARTINGALE";
export const SET_CLOSE_AFTER_DEAL = "SET_CLOSE_AFTER_DEAL";
export const SET_STOP_AFTER_DEAL = "SET_STOP_AFTER_DEAL";
export const CLOSE_ENTIRE_POSITION = "CLOSE_ENTIRE_POSITION";
export const CHANGE_ENTRY_PRICE = "CHANGE_ENTRY_PRICE";
export const CHANGE_RENT_PAIR = "CHANGE_RENT_PAIR";
export const CHANGE_RENT_FUND = "CHANGE_RENT_FUND";
export const SET_RENT_BOT_SELECTED = "SET_RENT_BOT_SELECTED";
export const CHANGE_ORDER_TYPE = "CHANGE_ORDER_TYPE";
export const CHANGE_ORDER_TYPE_EXIT = "CHANGE_ORDER_TYPE_EXIT";
export const CHANGE_ORDER_TYPE_RENT = "CHANGE_ORDER_TYPE_RENT";
export const CHANGE_ORDER_TYPE_EXIT_RENT = "CHANGE_ORDER_TYPE_EXIT_RENT";
export const SET_TRADING_VIEW_ENTRY_STATUS = "SET_TRADING_VIEW_ENTRY_STATUS";
export const SET_TRADING_VIEW_EXIT_STATUS = "SET_TRADING_VIEW_EXIT_STATUS";
export const CLOSE_TV_ALERT_SETTINGS = "CLOSE_TV_ALERT_SETTINGS";
export const EDIT_DEFAULT_ALERT_SETTINGS = "EDIT_DEFAULT_ALERT_SETTINGS";
export const CREATE_BOT_START = "CREATE_BOT_START";
export const CREATE_BOT_END = "CREATE_BOT_END";
//grid-bot
export const UPPER_RANGE_SELL_PRICE = "UPPER_RANGE_SELL_PRICE";
export const LOWER_RANGE_BUY_PRICE = "LOWER_RANGE_BUY_PRICE";
export const NUMBER_OF_ORDERS = "NUMBER_OF_ORDERS";
export const PER_ORDER_AMOUNT = "PER_ORDER_AMOUNT";
export const CHANGE_GRID_ENTRY_TYPE = "CHANGE_GRID_ENTRY_TYPE";
export const CHANGE_GRID_RANGE_DIRECTION = "CHANGE_GRID_RANGE_DIRECTION";
export const CHANGE_GRID_RANGE_VALUE = "CHANGE_GRID_RANGE_VALUE";
export const CHANGE_GRID_RANGE_PERIOD = "CHANGE_GRID_RANGE_PERIOD";
export const CHANGE_GRID_EXIT_WAIT_TIME = "CHANGE_GRID_EXIT_WAIT_TIME";
export const CHANGE_GRID_EXIT_OUTER_RANGE = "CHANGE_GRID_EXIT_OUTER_RANGE";
export const CHANGE_GRID_EXIT_HAS_TRADE = "CHANGE_GRID_EXIT_HAS_TRADE";
export const CHANGE_GRID_EXIT_NO_TRADE = "CHANGE_GRID_EXIT_NO_TRADE";
export const CHANGE_AVG_ENTRY_PRICE = "CHANGE_AVG_ENTRY_PRICE";
export const CHANGE_SELL_PRICE = "CHANGE_SELL_PRICE";
export const CHANGE_SECOND_EXCHANGE = "CHANGE_SECOND_EXCHANGE";
export const ORDER_LAYER_CHANGED = "ORDER_LAYER_CHANGED";
export const CHANGE_EXIT_STRATEGY = "CHANGE_EXIT_STRATEGY";
export const CHANGE_FUND_SECONDARY = "CHANGE_FUND_SECONDARY";
export const STRATEGY_DIRTY = "STRATEGY_DIRTY";
export const DAISY_CHAIN = "DAISY_CHAIN";
export const FIRST_IN_DAISY_CHAIN = "FIRST_IN_DAISY_CHAIN";
export const TRIGGER_DAISY_BOT = "TRIGGER_DAISY_BOT";
export const DO_NOT_DEACTIVATE_BOT = "DO_NOT_DEACTIVATE_BOT";
export const MIN_PRICE_GAP_PROFIT = "MIN_PRICE_GAP_PROFIT";
export const CHANGE_TRASACTION_FEE = "CHANGE_TRASACTION_FEE";
export const CHANGE_IS_HOLD_CURRENCY = "CHANGE_IS_HOLD_CURRENCY";

export const SET_ORDER_FILLED_EMAIL = "SET_ORDER_FILLED_EMAIL";
export const SET_ORDER_FILLED_EMAIL_RENT = "SET_ORDER_FILLED_EMAIL_RENT";
